import { useEffect, useState } from "react";
import { Dashboard } from "../../components/Dashboard/Dashboard";
import { Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Circular } from "../../components/Circular/Circular";
import connectTopLine from "../../components/HOC/connectTopLine";
import { applyCurrencyFormatters } from "../../common/helper";
import "bootstrap/dist/css/bootstrap.min.css";
import fetchData from "../../api/data";
import Export from "../../components/Export/Export";
import { Box, Tooltip } from "@mui/material";
import { toastApiError } from "../../utils/toastApiError";
import useAuth from "../../auth/useAuth";

const perPageList = [10, 50, 100];

const Monthly = ({ datesFilter, brandsFilter }) => {
  const auth = useAuth();
  const [stats, setStats] = useState({});
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      id: "Month",
      name: "Month",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Clicks",
      selector: row => row.clicks,
      sortable: true,
      right: true,
      compact: true,
    },
    {
      name: "Registered",
      selector: row => row.registrations,
      sortable: true,
      right: true,
      compact: true,
    },
    {
      name: "FTDs",
      selector: row => row.ftds,
      sortable: true,
      right: true,
      compact: true,
    },
    {
      name: "Deposits",
      selector: row => row.numberOfDeposits,
      sortable: true,
      right: true,
      compact: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: <Box>Deposits Value</Box>,
      selector: row => row.deposits,
      sortable: true,
      format: "currency",
      right: true,
      omit: !auth.user.authorities.includes('VIEW_DEPOSITS')
    },
    {
      name: <Box>Net Cash</Box>,
      selector: row => row.netCash,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: <Tooltip
          title="If the Rev Share is negative at the end of a month, we'll pay the full CPA Commission but the negative Rev Share Commission is carried over"><Box>Carried
        Over Rev Share *</Box></Tooltip>,
      selector: row => row.negativeRevShareCarriedOver,
      sortable: true,
      format: "currency",
      right: true,
      style: {
        borderLeft: "2px solid rgba(0,0,0,.12)",
      },
    },
    {
      name: <Box>Rev Share Commission</Box>,
      selector: row => row.revShareCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: <Box>CPA Commission</Box>,
      selector: row => row.cpaCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
    {
      name: <Box>Month's Commission</Box>,
      selector: row => row.totalCommission,
      sortable: true,
      format: "currency",
      right: true,
    },
  ];

  useEffect(() => {
    setLoading(true);
    return fetchData({
      url: `/api/v3/reports/monthly?${brandsFilter}`,
      onSuccess: (response) => {
        setReports(response.data.reports);
        setStats(response.data.totalsReport);
      },
      onError: toastApiError,
      onCallback: () => setLoading(false),
    });
  }, [brandsFilter, datesFilter]);

  return (
    <Row>
      <Dashboard stats={stats} />
      <div className="data__wrapper">
        <DataTable
          title="Monthly"
          defaultSortFieldId="Month"
          defaultSortAsc={false}
          columns={applyCurrencyFormatters(columns)}
          data={reports}
          progressPending={loading}
          pagination
          progressComponent={<Circular />}
          paginationRowsPerPageOptions={perPageList}
          actions={<Export rows={reports} />}
        />
      </div>
    </Row>
  );
};

export default connectTopLine(false, true, Monthly);
