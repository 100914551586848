export default [
  {
    singleReviewLink: "https://casinosanalyzer.com/online-casinos/miamijackpots.com",
    singleReviewLinkText: "casinosanalyzer.com",
    reviewText: "“We have just started working with Winning Partner. They provided us with all the necessary information we requested regarding their brand. We consider their brands among the top online casinos. It's a pleasure to work with such a professional team!”",
    reviewAuthor: "Jack"
  },
  {
    singleReviewLink: "https://win.expert",
    singleReviewLinkText: "win.expert",
    reviewText: "We are very pleased with the results of our partnership with Winning Partner, as the conversion rate is quite high. Partner managers are ready to help with almost any tasks, which is not so common in the industry. We wish you further development, new brands and new geolocations.",
    reviewAuthor: "Elena"
  },
  {
    singleReviewLink: "https://gamblescope.com/casino/miami-jackpots-casino.html",
    singleReviewLinkText: "gamblescope.com",
    reviewText: "Working with Winning Partner has been very beneficial for us. They have a group of solid brands which always convert well. We are always happy to hear about a new brand from Winning Partner.",
    reviewAuthor: "Anton"
  },
  {
    singleReviewLink: "https://www.thegamblerbay.com/online-casinos/bb-casino-review/",
    singleReviewLinkText: "thegamblerbay.com",
    reviewText: "Winning Partner are easy to collaborate with, they are always responsive and very professional in their work. We look forward to continuing working with them for the foreseeable future.",
    reviewAuthor: "Ivan"

  },
  {
    singleReviewLink: "https://findcasinosnow.com",
    singleReviewLinkText: "findcasinosnow.com",
    reviewText: "Winning partner has a lot of high quality brands in their portfolio. It’s one of the leading affiliate programs in the industry. Josh is super responsive and friendly. Find Casinos Now enjoy working with them.",
    reviewAuthor: "Caroline",
  },
  {
    singleReviewLink: "https://casinomentor.com/reviews/miami-jackpots",
    singleReviewLinkText: "casinomentor.com",
    reviewText: "Really great people behind the scenes at Winning Partners. Great to be working with such a responsive affiliate team.",
    reviewAuthor: "Selina Anne",
  },
  {
    singleReviewLink: "https://hqgambling.com/igaming-company/miami-jackpots-casino-review/",
    singleReviewLinkText: "HQ Gambling",
    reviewText: "At HQgambling we review online casinos and provide their exclusive promotions. We are really happy with our new partnership with Winning Partner. They already offer plenty of high quality brands and keep adding more to their portfolio. Josh, their affiliate manager, is really friendly and knowledgeable. Pleasure to work with such a professional team. Looking forward to a beneficial partnership for the foreseeable future.",
    reviewAuthor: "Nurer",
  },
  {
    singleReviewLink: "https://canadaonlinecasinos.com",
    singleReviewLinkText: "Canada Online Casino",
    reviewText: "We recently joined the Winning Partner Affiliate Program. Since the start we got great guidance and assistance from the casino affiliate team. The COC team is looking forward to a long-term cooperation.",
    reviewAuthor: "COC Team",
  },
  {
    singleReviewLink: "https://www.playcasino.com/casino/bbcasino",
    singleReviewLinkText: "Play Casino",
    reviewText: "We are happy to start cooperating with Winning Partner affiliate program. Its casino brands have all that is required to succeed in the competitive market - great product, unique promotions, high quality customer service. BBCasino is a great addition to our Playcasino.com portal.",
    reviewAuthor: "Gil",
  },
  {
    singleReviewLink: "https://gamblingaffiliatereview.com",
    singleReviewLinkText: "Gambling Affiliate Review",
    reviewText: "As a global affiliate targeting all markets, a friend in the industry recommended us to sign up with Winning Partner. We’re happy we did as the click to sign-up and sign-up to deposit ratio has been fantastic among the many versatile brands that are offered by the company.",
    reviewAuthor: "GAR Team",
  },
  {
    singleReviewLink: "https://www.ebetfinder.com",
    singleReviewLinkText: "EBet Finder",
    reviewText: "From the very start of our partnership, the affiliate team at Winning Partner has given us a royal treatment, accommodating our needs to create a win-win situation for us both. Along with a wide range of quality brands to list, we couldn’t be more satisfied. These guys are just great!",
    reviewAuthor: "Mattias",
  },
  {
    singleReviewLink: "https://www.onlinecasinogroups.com/skillonnet-casinos/",
    singleReviewLinkText: " Online Casino Groups",
    reviewText: "We are very pleased with our new relationship with Winning Partners Affiliates. Thanks to their amazing team we felt right at home. Their affiliate program is amazing as well. On top of that, they have an amazing brand from SkillOnNet Ltd, Miami Jackpots Casino. This one can't be missed from our SkillOnNEt Casinos portfolio. We highly recommend joining Winning Partner Affiliates.",
    reviewAuthor: "OCG Team",
  },
  {
    singleReviewLink: "https://playrealmoneyslots.co.uk/top100onlinecasinos/",
    singleReviewLinkText: "Play Real Money Slots",
    reviewText: "We are very happy with our new relationship with Winning Partners. They have an excellent affiliate team and a great program. Their brands BB Casino and Miami Jackpot Casino fit perfectly in our Top 100 online casinos for UK players. At PlayRealMoneySlots we find it a joy to work with brands like these. We highly recommend working with Winning Partner.",
    reviewAuthor: "Jannes",
  },
  {
    singleReviewLink: "https://www.supercasinosites.com/online-casinos/miami-jackpots-casino-review/",
    singleReviewLinkText: "Super Casino Sites",
    reviewText: "Working with Winning Partner is a pleasure. Miami Jackpots as a brand is wonderful and has been a great addition to our network. We trust the brand will attract many of our visitors. Highly Recommended!",
    reviewAuthor: "Joshua",
  },
  {
    singleReviewLink: "https://www.igamblingsites.com/casinos/miami-jackpots",
    singleReviewLinkText: "IgamblingSites",
    reviewText: "We chose to work with Winning Partner Affiliates due to the choice of premium brands they offer. So far, everything has been spot on, our account manager is great, and we have plenty of tools at our disposal. Overall, we can see this being a long term partnership.",
    reviewAuthor: "Hannah",
  },
  {
    singleReviewLink: "https://bestslingosites.co.uk/slingo-reviews/miami-jackpots",
    singleReviewLinkText: "Best Slingo Sites",
    reviewText: "Winning Partner Affiliates are an absolute joy to work with. Their support getting their program live has been superb and we are excited to work together on many projects moving forward. If you are looking for a casino program to promote, look no further.",
    reviewAuthor: "Blake",
  },
  {
    singleReviewLink: "https://mrwin.com/casino/miami-jackpots-casino",
    singleReviewLinkText: "Mrwin",
    reviewText: "Winning Partner is a top-notch affiliate program with a fantastic portfolio of casino sites. Josh, their winning affiliate program manager, has always replied promptly when we’ve needed help or advice.",
    reviewAuthor: "Daniel ",
  },
  {
    singleReviewLink: "https://www.spicycasinos.com/",
    singleReviewLinkText: "Spicy Casinos",
    reviewText: "Winning Partner is an exceptional affiliate program with great variety of brands and highly professional team. We are looking forward to a long and mutually beneficial partnership.",
    reviewAuthor: "Yana",
  },
  {
    singleReviewLink: "https://www.casinoadvisor.eu/",
    singleReviewLinkText: "Casino Advisor",
    reviewText: "The review team of CasinoAdvisor.eu found BB Casino recently, when searching for new online casinos. We are impressed by the quality of this website and decided to share it with our visitors. Also the contact with the affiliate manager of winningpartner.com has been very smooth, we are looking forward to a bright future together.",
    reviewAuthor: "Leon ",
  },
  {
    singleReviewLink: "https://mr-gamble.com",
    singleReviewLinkText: "Mr Gamble",
    reviewText: "Winning Partner is a great partner with brands that are converting really well, their affiliate team is always easy to reach and they are always happy to help out.",
    reviewAuthor: "Tero ",
  },
  {
    singleReviewLink: "http://ukbingosites.co.uk",
    singleReviewLinkText: "UK Bingo Sites",
    reviewText: "Winning Partners provides top notch deals without the trouble of account management. We warmly recommend their services to any affiliate looking to increase revenue!",
    reviewAuthor: "Tero ", //last
  },
  {
    singleReviewLink: "https://onlinecasinosdk.com/",
    singleReviewLinkText: "Online Casino DK",
    reviewText: "It’s a pleasure to work with Winning Partners. We’ve been extremely pleased with our co-operation. They have strong casino brands with high conversion rates which leads to great results. Highly recommended for Danish players!",
    reviewAuthor: "Sveta",
  },
  {
    singleReviewLink: "https://bonusmaniac.com/",
    singleReviewLinkText: "Bonus Maniac",
    reviewText: "We are grateful to have Winning Partner with us. They operate a few solid casino brands, and the team here is easy to collaborate with. We look forward to continue strengthen our mutually beneficial cooperation!",
    reviewAuthor: "Cheryl",
  },
  {
    singleReviewLink: "https://gamblizard.com/",
    singleReviewLinkText: "Gamblizard",
    reviewText: "In this industry, it’s essential to work with a reliable and trustworthy brand, and Winning Partners are both of these things and much more. This is particularly important to Jame Giles, our Co-Founder and Head of Product, who wants to establish lasting connections with authentic partners. Therefore, it’s safe to say both Giles and Gamblizard will keep putting their trust in them.",
    reviewAuthor: "Sveta",
  },
  {
    singleReviewLink: "https://newonlinecasinocanada.com/",
    singleReviewLinkText: "New Online Casino Canada",
    reviewText: "We are very pleased to be working with Winning Partners. They have a huge number of excellent online casino brands in Canada, they are safe, fast and have a complete gaming platform. Happy to be able to work with them.",
    reviewAuthor: "Fernando",
  },
  {
    singleReviewLink: "https://sammybingo.co.uk/",
    singleReviewLinkText: "Sammy Bingo",
    reviewText: "Winning Partners is one of the best partners of 2022 offering great brands that not only convert well but players seem to love and enjoy too. The affiliate team also go above and beyond to help with all their partners needs. Highly recommended!",
    reviewAuthor: "Ivan",
  },
  {
    singleReviewLink: "https://kasinotilmanrekisteroitymista.com/",
    singleReviewLinkText: "Kasinot Ilman Rekisteröitymistä",
    reviewText: "We want to recommend Winning Partners as a casino operator, we are very happy to work with them and we hope to continue doing so for a long time",
    reviewAuthor: "Fernando",
  },
  {
    singleReviewLink: "https://askbonus.com/",
    singleReviewLinkText: "Ask Bonus",
    reviewText: "Although we've just started with Winning Partners, we expect nice cooperation. They provided us with all the necessary information we requested regarding their brand and bonuses. We're certain there is a long and promising partnership ahead of us",
    reviewAuthor: "Serge",
  },
  {
    singleReviewLink: "https://www.casivo.co.uk",
    singleReviewLinkText: "Casivo",
    reviewText: "Casivo is looking forward to a fruitful collaboration with Winning Partner. The commission rates offered are very rewarding and the brands are known to convert well. We are happy to have started this partnership and recommend you start one too!",
    reviewAuthor: "Matteo",
  },
  {
    singleReviewLink: "https://www.newslotsite.org.uk",
    singleReviewLinkText: "New Slot Site",
    reviewText: "We are delighted to have become one of Winning Partner’s affiliates. The Affiliate Manager seems very keen on working together to get the best possible outcomes for all. We are sure this partnership will turn out to be very beneficial.",
    reviewAuthor: "Johan",
  },
  {
    singleReviewLink: "https://casinogrounds.com",
    singleReviewLinkText: "CasinoGrounds",
    reviewText: "CasinoGrounds is happy to start working with Winning Partner. Miami Jackpots is a great brand for the UK market and we're excited to include it in our portfolio. Bohdan from the Winning Partner team is always professional and easy to communicate with. We're looking forward to good conversions and building a long-lasting partnership!",
    reviewAuthor: "Kasper",
  },
  {
    singleReviewLink: "https://aboutslots.com/",
    singleReviewLinkText: "Aboutslots",
    reviewText: "Winningpartner is the type of affiliate Aboutslots is always seeking: reliable, with great conversion rates and wonderful platforms for our users. We 100% recommend working with them if you aren’t already, as it’s not something you’ll ever regret!",
    reviewAuthor: "Armin",
  },
  {
    singleReviewLink: "https://www.slotscalendar.com/",
    singleReviewLinkText: "SlotsCalendar",
    reviewText: "SlotsCalendar and Winning Partner have started to work together almost a year ago. From the very beginning of the partnership we were pleased with the communication between us and their Affiliate team. They have always been professional and helpful with our needs. We wish for a fruitful and long term partnership with them. We, at SlotsCalendar, strongly recommend working with Winning Partner.",
    reviewAuthor: "Laura",
  },
  {
    singleReviewLink: "https://www.casino24.dk/",
    singleReviewLinkText: "Casino24",
    reviewText: "We are delighted to team up with Miami Jackpots towards our Danish audience. Winning Partner is a respected partner and we are looking forward to work for many years to come.",
    reviewAuthor: "Nicolai",
  },
  {
    singleReviewLink: "https://storspilleren.com/",
    singleReviewLinkText: "Storspilleren",
    reviewText: "Winning Partner is a professional company with licensed brands. They take affiliation very seriously and that is why we at Storspilleren teamed up with Winning Partner to provide the best brands towards our DK users.",
    reviewAuthor: "Karl",
  },
  {
    singleReviewLink: "https://gratischancer.dk/",
    singleReviewLinkText: "GratisChancer",
    reviewText: "If you are looking for a Danish brand that converts well, has a professional and responsive affiliate team, then Miami Jackpots is one of those brands you must have on your website. We from GratisChancer are grateful to work with Winning Partner.",
    reviewAuthor: "Alma",
  },
  {
    singleReviewLink: "https://bojoko.ca/",
    singleReviewLinkText: "bojoko",
    reviewText: "It’s been an absolute pleasure working with Winning Partner. Well converting brands and professional affiliate team have made our journey with them a pleasant one!",
    reviewAuthor: "Tuomas",
  },
  {
    singleReviewLinkText: "LUCKLANDIA",
    singleReviewLink: "https:/lucklandia.com/",
    reviewText:"LUCKLANDIA is happy to partner with the Winning Partner. Their affiliate team is professional, streamlined, focused, and collaborative. Winning Partner is the partner to wish for. For casino affiliates seeking solid partnerships, Winning Partner is our top recommendation.",
    reviewAuthor:"Milicia"
  }
]